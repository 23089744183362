import React, {useContext, useEffect} from "react"
import SEO from "../components/seo"
import LayoutMaster from "../components/layout"
import '../styles/global.css'
import PageBanner from '../components/pageBanner'
import {GlobalStateContext} from '../context/GlobalContextProvider'

export default function Terms({data, location}){
    const siteTitle = data.site.siteMetadata.title
    const state = useContext(GlobalStateContext)
    const {menu, impactEveryDay} = state.language
    const header = () => document.querySelector('header')
    const footer = () => document.querySelector('footer')
    useEffect(()=>{
        header().style.display = "none"
        footer().style.display = "none"
    })
    
    return(
        <LayoutMaster location={location} title={siteTitle}>
          <SEO title={menu[7].text} />
          <PageBanner bgImg="https://www.limpabrasil.org/2018/wp-content/uploads/2020/06/bg2-1.png">
            <h2>{impactEveryDay.title}</h2>
            <p>
              {impactEveryDay.description}
            </p>
            <section style={{marginTop:30}}>
              {
                  impactEveryDay.content.map((term, index)=>(
                  <section key={index}>
                    <h4>{term.title}</h4>
                    <div>{term.text.map((item, index)=>(<p key={index}>{item}</p>))}</div>
                  </section>
                ))
              }
            </section>
          </PageBanner>
        </LayoutMaster>
    )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`